import React from 'react';

import logo from './images/logo.svg';
import arrow from './images/arrow.svg';
import bg from './images/bg-80.jpg';

function App() {
  return (
    <div
      className={`max-w-fit flex min-h-dvh flex-col justify-start sm:justify-between font-sans`}
    >
      <img
        src={bg}
        alt="bg"
        className="absolute right-0 bottom-0 -z-10 w-[100%] sm:w-3/4"
      />
      <div className="flex flex-row  w-dvw justify-between items-center py-10 px-5 sm:p-10">
        <img
          src={logo}
          alt="founder capital"
          className="w-[150px] sm:w-[300px]"
        />
        <a
          href="https://fi.co"
          className="rounded-full border-primary border justify-center items-center flex cursor-pointer hover:bg-primary hover:text-white transition-all px-4 py-1 sm:px-6 sm:py-2 text-xs sm:text-base"
        >
          Founder Institute
        </a>
      </div>

      <div className="flex flex-col py-20 px-10 sm:py-10 md:px-24">
        <h1 className="font-semibold text-4xl sm:text-6xl md:text-8xl lg:text-9xl">
          Backing Top
          <br className="hidden sm:block" /> Founders
          <br className="hidden sm:block" /> Worldwide
        </h1>
        <p className="pt-10 text-base md:text-xl lg:text-2xl">
          The{' '}
          <a href="https://fi.co" className="text-primary underline">
            Founder Institute
          </a>{' '}
          VC fund that invests
          <br className="hidden sm:block" /> and supports top portfolio
          companies from
          <br className="hidden sm:block" /> the world&apos;s largest startup
          network.
        </p>
      </div>

      <div className="flex py-10 px-10 md:px-24">
        <a
          href="mailto:info@foundercapital.vc"
          className="flex justify-start items-center text-primary text-xl font-semibold group"
        >
          More{' '}
          <span className="transition-all group-hover:ml-2">
            <img src={arrow} className="ml-2 mt-1 w-5" />
          </span>
        </a>
      </div>
    </div>
  );
}

export default App;
